import { useEffect } from 'react';

import { appRoute } from '@/app/routes/AppRoute';
import { Preloader } from '@/components/ui/Preloader/Preloader';
import { AppLinks } from '@/config/routes';
import { getCookie } from '@/helpers/getCookie';

export const OAuthPage = () => {
  useEffect(() => {
    const accessToken = getCookie('accessToken');

    if (accessToken) {
      appRoute.navigate('/', { replace: true });
    } else {
      window.location.replace(AppLinks.oauthLogin);
    }
  }, []);

  return <Preloader />;
};
