import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { authApi } from '@/api/auth';
import { favoritesApi } from '@/api/favorites/slice';
import { filesApi } from '@/api/files';
import { giphyApi } from '@/api/giphy';
import { settingsApi } from '@/api/settings';
import { unsplashApi } from '@/api/unsplash';
import { usersApi } from '@/api/users/slice';
import { workspacesApi } from '@/api/workspaces';
import { layoutSlice } from '@/components/layout/AppLayout/slice';
import { changePasswordModalSlice } from '@/components/layout/Settings/Pages/account/ChangePasswordModal/slice';
import { deleteAccountStepperSlice } from '@/components/layout/Settings/Pages/account/DeleteAccountStepper/slice';
import { createPageModalSlice } from '@/features/CreatePageModal/slice';
import { activeUsersSlice } from '@/features/Editor/slice';
import { pinModalSlice } from '@/features/PinModal/slice';
import { searchModalSlice } from '@/features/SearchModal/slice';
import { createWorkspaceModalSlice } from '@/features/slice';
import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    // ENDPOINTS
    [usersApi.reducerPath]: usersApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [workspacesApi.reducerPath]: workspacesApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [unsplashApi().reducerPath]: unsplashApi().reducer,
    [giphyApi().reducerPath]: giphyApi().reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    // OTHER
    [layoutSlice.reducerPath]: layoutSlice.reducer,
    [createWorkspaceModalSlice.reducerPath]: createWorkspaceModalSlice.reducer,
    [createPageModalSlice.reducerPath]: createPageModalSlice.reducer,
    [changePasswordModalSlice.reducerPath]: changePasswordModalSlice.reducer,
    [pinModalSlice.reducerPath]: pinModalSlice.reducer,
    [deleteAccountStepperSlice.reducerPath]: deleteAccountStepperSlice.reducer,
    [searchModalSlice.reducerPath]: searchModalSlice.reducer,
    [activeUsersSlice.reducerPath]: activeUsersSlice.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'workspacesApi/executeMutation/fulfilled',
          'pinModal/open',
        ],
        ignoredPaths: ['workspacesApi.mutations', 'pinModal.submitFunc'],
      },
    })
      .concat(usersApi.middleware)
      .concat(authApi.middleware)
      .concat(workspacesApi.middleware)
      .concat(filesApi.middleware)
      .concat(favoritesApi.middleware)
      .concat(unsplashApi().middleware)
      .concat(giphyApi().middleware)
      .concat(settingsApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
