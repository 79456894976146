import { customBaseQuery } from '@/api/customBaseQuery';
import { layoutSlice } from '@/components/layout/AppLayout/slice';
import { createApi } from '@reduxjs/toolkit/query/react';
import i18next from 'i18next';

import { getCurrentUserId } from '../helpers/token';
import { ApiUsers } from './types';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: customBaseQuery(),
  tagTypes: ['CurrentUser'],
  endpoints: (builder) => ({
    getCurrentUser: builder.query<ApiUsers.IGetByIdResponse, void>({
      query: () => ({
        url: `/v1/users/${getCurrentUserId()}`,
        method: 'GET',
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            layoutSlice.actions.setActiveWorkspaceId(
              data.activeWorkspaceId || '',
            ),
          );
          i18next.changeLanguage(data.lang);
        } catch (err) {
          console.error(err);
        }
      },
      providesTags: (res) => (res ? ['CurrentUser'] : []),
    }),
    getById: builder.query<ApiUsers.IGetByIdResponse, ApiUsers.IGetByIdRequest>(
      {
        query: (id) => ({
          url: `/v1/users/${id}`,
          method: 'GET',
        }),
      },
    ),
    deleteUser: builder.mutation<void, ApiUsers.IDeleteRequest>({
      query: (data) => ({
        url: `/v1/users/${data?.id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          console.error(err);
        }
      },
      invalidatesTags: (_, err) => (err ? [] : ['CurrentUser']),
    }),
    patchUser: builder.mutation<ApiUsers.IUser, Partial<ApiUsers.IUser>>({
      query: (user) => ({
        url: `/v1/users/${user.id}`,
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: (_, err) => (err ? [] : ['CurrentUser']),
    }),
    quitAllDevices: builder.mutation<void, void>({
      query: () => ({
        url: `/v1/logout-all`,
        method: 'POST',
      }),
    }),
  }),
});
