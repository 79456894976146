import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { authApi } from '@/api/auth';
import { settingsApi } from '@/api/settings';
import { CUSTOMER } from '@/config/constants';
import { AppRoutes } from '@/config/routes';
import { useCurrentUserData } from '@/hooks/useCurrentUserData';
import { Divider, Group, Menu, Text } from '@mantine/core';
import {
  IconBug,
  IconCertificate,
  IconDeviceLaptop,
  IconLogout,
  IconSettings,
  IconSmartHome,
  IconUserCircle,
} from '@tabler/icons-react';

import { UserAvatar } from '../UserAvatar';

export const UserMenu = () => {
  const { t } = useTranslation();
  const data = useCurrentUserData();
  const { data: settingsData } = settingsApi.endpoints.get.useQueryState();
  const [externalLogout] = settingsApi.useLogoutMutation();
  const [localLogout] = authApi.useLogoutMutation();

  const handleLogout = async () => {
    if (CUSTOMER === 'R7' || CUSTOMER === 'CRUDOC') {
      Promise.all([localLogout().unwrap(), externalLogout().unwrap()]).then(
        () => {
          location.replace('/');
        },
      );
    } else {
      location.replace('/');
    }
  };

  const settings = settingsData?.ModuleSettings;
  const hasAdminAccess = settings?.ModulesAccess.includes('Pages');
  const hasPayments = settings?.ModulesAccess.includes('Payments');
  const adminUrl = settings?.ModuleUrls.find((el) => el.Name === 'Admin')?.Url;

  const showAdditional = CUSTOMER === 'R7' || CUSTOMER === 'CRUDOC';

  return (
    <Menu
      shadow="md"
      position="bottom-end"
      keepMounted={false}
      transitionProps={{ transition: 'pop-top-right', duration: 150 }}
    >
      <Menu.Target>
        <UserAvatar
          user={data}
          size={36}
          style={{
            cursor: 'pointer',
          }}
        />
      </Menu.Target>
      <Menu.Dropdown>
        <Group px="sm" pt={6} maw={'100dwh'} w={300}>
          <UserAvatar user={data} size={50} />
          <div style={{ flex: 1, minWidth: 0 }}>
            <Text fz={18} truncate="end">
              {data.name}
            </Text>
            <Text fz={14} c="dimmed" truncate="end">
              {data.email}
            </Text>
          </div>
        </Group>

        <Divider mt="xs" mb={6} />

        {showAdditional && (
          <Menu.Item
            component="a"
            href={`${adminUrl}/dashboard`}
            leftSection={<IconSmartHome stroke={1.5} size={20} />}
          >
            {t('userMenu.home')}
          </Menu.Item>
        )}

        {showAdditional && hasAdminAccess && (
          <Menu.Item
            component="a"
            href={`${adminUrl}/profile`}
            leftSection={<IconUserCircle stroke={1.5} size={20} />}
          >
            {t('userMenu.profile')}
          </Menu.Item>
        )}

        {CUSTOMER === 'DUDOC' && (
          <Link to={AppRoutes.settingsAccount}>
            <Menu.Item leftSection={<IconSettings stroke={1.5} size={20} />}>
              {t('userMenu.settings')}
            </Menu.Item>
          </Link>
        )}

        {showAdditional && hasPayments && (
          <Menu.Item
            component="a"
            target="_blank"
            href={'https://payments.r7-space.ru'}
            leftSection={<IconCertificate stroke={1.5} size={20} />}
          >
            {t('userMenu.subscription')}
          </Menu.Item>
        )}

        {showAdditional && (
          <Menu.Item
            component="a"
            target="_blank"
            href={'https://preview.r7-space.ru'}
            leftSection={<IconDeviceLaptop stroke={1.5} size={20} />}
          >
            {t('userMenu.download')}
          </Menu.Item>
        )}

        {showAdditional && (
          <Menu.Item
            component="a"
            target="_blank"
            href={'https://r7-space.ru/feedback?from=Pages'}
            leftSection={<IconBug stroke={1.5} size={20} />}
          >
            {t('userMenu.reportBug')}
          </Menu.Item>
        )}

        <Menu.Item
          onClick={handleLogout}
          leftSection={<IconLogout stroke={1.5} size={20} />}
        >
          {t('userMenu.logout')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
