import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const APP_API = import.meta.env.VITE_APP_API || '';
const BACKEND_TYPE = import.meta.env.VITE_BACKEND_TYPE || '';

const DEFAULT_BASE_URL = BACKEND_TYPE === 'R7' ? '/app' : APP_API;

export const customBaseQuery = (baseUrl = DEFAULT_BASE_URL) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    credentials: 'include',
  });

  return baseQuery;
};
