import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

import { APP_NAME } from '@/config/constants';
import { CreatePageModal } from '@/features/CreatePageModal/CreatePageModal';
import { CreateWorkspaceModal } from '@/features/CreateWorkspace';
import { PageHeader } from '@/features/PageHeader/PageHeader';
import { SelectActiveWorkspace } from '@/features/SelectActiveWorkspace/SelectActiveWorkspace';
import { useActiveWorkspace } from '@/hooks/useActiveWorkspace';
import { useCurrentUserData } from '@/hooks/useCurrentUserData';
import { useOpenedPage } from '@/hooks/useOpenedPage';
import { WorkspaceProvider } from '@/hooks/useWsProvider';

import { SearchModal } from '../../../features/SearchModal/SearchModal';
import {
  AppLayout,
  NavbarHeader,
  NavbarScrollableContent,
} from '../AppLayout/AppLayout';
import { useNavbarScroll } from '../AppLayout/useNavbarScroll';
import { AppPagesMenu } from '../Sidebar/components/AppNavMenu/AppNavMenu';
import { Favorites } from '../Sidebar/components/Favorites/Favorites';
import { Footer } from '../Sidebar/components/Footer';
import { MenuItems } from '../Sidebar/components/MenuItems/MenuItems';
import { WorkspaceMenu } from '../WorkspaceMenu/WorkspaceMenu';

export const AppRouteLayout = () => {
  const user = useCurrentUserData();
  const activeWs = useActiveWorkspace();

  if (!activeWs || !user.workspaces.length) {
    return <SelectActiveWorkspace />;
  }

  return (
    <>
      <Helmet>
        <title>{APP_NAME}</title>
      </Helmet>
      <WorkspaceProvider>
        <AppLayout
          content={<Outlet />}
          header={<AppHeader />}
          navbar={<AppNavbar />}
        />
        <CreateWorkspaceModal />
        <CreatePageModal />
        <SearchModal />
      </WorkspaceProvider>
    </>
  );
};

const AppHeader = () => {
  const page = useOpenedPage();

  if (page) {
    return <>{page?.data && <PageHeader page={page.data} />}</>;
  }
};

const AppNavbar = () => {
  const { onScroll, showDivider } = useNavbarScroll();

  return (
    <>
      <NavbarHeader showDivider={showDivider}>
        <WorkspaceMenu />
      </NavbarHeader>

      <NavbarScrollableContent onScroll={onScroll}>
        <MenuItems />
        <Favorites />
        <AppPagesMenu />
      </NavbarScrollableContent>
      <Footer />
    </>
  );
};
