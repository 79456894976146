import { authApi } from '@/api/auth';
import { settingsApi } from '@/api/settings';
import { NoData } from '@/components/ui/NoData/NoData';
import { CUSTOMER } from '@/config/constants';
import { Container } from '@mantine/core';
import { t } from 'i18next';

import classes from './AppError.module.css';

export const AppError = () => {
  const [externalLogout] = settingsApi.useLogoutMutation();
  const [localLogout] = authApi.useLogoutMutation();

  const handleLogout = async () => {
    if (CUSTOMER === 'R7' || CUSTOMER === 'CRUDOC') {
      Promise.all([localLogout().unwrap(), externalLogout().unwrap()]).then(
        () => {
          location.replace('/');
        },
      );
    } else {
      location.replace('/');
    }
  };

  return (
    <Container className={classes.root}>
      <NoData
        title={t('errorMessage')}
        description={t('reloadSuggestion')}
        illustration="500"
        buttons={[
          {
            children: t('reloadButton'),
            onClick: () => window.location.reload(),
          },
          {
            children: t('logoutButton'),
            onClick: handleLogout,
            variant: 'outline',
          },
        ]}
      />
    </Container>
  );
};
